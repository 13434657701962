import React from "react"
import PropTypes from "prop-types"
import { Box, Stack, Typography } from "@mui/material"
import { ContentWrapper, Banner } from "../components"

import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const PartnerPageTemplate = ({ data }) => {
  const { title, subtitle, partner, banner } = data.markdownRemark.frontmatter

  return (
    <>
      <ContentWrapper title={title} subtitle={subtitle}>
        <Stack direction="row" flexWrap="wrap" mx={{ md: "-1.3rem" }}>
          {partner.map((item, index) => {
            const { description, image } = item
            return (
              <Box
                key={image.src.id + index}
                width="100%"
                m={{ base: "1.5rem 0", md: "1.5rem" }}
                maxWidth={{
                  base: "calc(100%)",
                  md: "calc(50% - 3rem)",
                  lg: "calc(33% - 3rem)",
                }}
              >
                <Stack
                  width="100%"
                  height="180px"
                  bgcolor="#FBFBFF"
                  alignItems="center"
                  justifyContent="center"
                  py={3}
                >
                  <GatsbyImage
                    image={image.src.childImageSharp.gatsbyImageData}
                    alt={image.alt}
                    objectFit="contain"
                    style={{ maxWidth: "180px" }}
                  />
                </Stack>
                <Typography fontSize="0.875rem" color="#464646">
                  {description}
                </Typography>
              </Box>
            )
          })}
        </Stack>
      </ContentWrapper>
      <Banner data={banner} />
    </>
  )
}

PartnerPageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PartnerPageTemplate

export const query = graphql`
  fragment PartnerPage on MarkdownRemarkFrontmatter {
    title
    subtitle
    partner {
      description
      image {
        alt
        src {
          id
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    banner {
      title
      subtitle
      button {
        title
        link
      }
    }
  }
`
